<script>
export default {
  props: {
    suggection: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<template>
  <div class="rounded-8px bg-white p-20px px-40px lg:p-10 pt-50px lg:pt-65px pb-5 h-406px flex flex-col justify-between">
    <img src="@/assets/images/comma.png" alt="comma" class="absolute left-30px lg:left-53px top-20px lg:top-39px w-24px" />
    <img src="@/assets/images/comma.png" alt="comma" class="absolute right-25px bottom-39px w-24px transform rotate-180" />
    <p class="mb-20px line-clamp-6 text-[#727577]">
      {{ suggection.suggection }}
    </p>
    <div class="flex items-center justify-end pr-50px">
      <img :src="suggection.image" class="w-64px mr-12px" alt="" />
      <div>
        <p class="text-[#4380BB] font-medium border-b-2 border-solid mb-8px border-[#B3D2EF]">{{ suggection.name }}</p>
        <p class="text-[#4380BB] font-medium text-14px line-clamp-1">{{ suggection.school }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
