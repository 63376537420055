<script>
import { apiGetBanner, apiGetLatestNews, apiGetRecentActivities } from "@/api/home";
import { studentSuggections } from "@/assets/data/studentSuggection";
import StudentSuggection from "@/components/StudentSuggection";
import NewsArticleComp from "@/components/NewsArticleComp";
import Activities from "@/components/Activities";
import Swiper from "swiper";
import * as dayjs from "dayjs";

export default {
    components: { NewsArticleComp, Activities, StudentSuggection },
    data() {
        return {
            banners: { data: [] },
            lastNews: [],
            recentActivities: [],
            banner: null,
            students: null,
            comps: "NewsArticleComp",
            studentSuggections, // 生學的推薦
            sec: 0, // guide 圖片的跳動用
            timer: null,
            subtitleIdx: -1,
            openMobileMenu: false,
        };
    },
    methods: {
        bannerSwiper() {
            this.banner = new Swiper(".mySwiper", {
                loop: true,
                navigation: {
                    nextEl: ".banner-next",
                    prevEl: ".banner-prev",
                },
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                },
            });
        },
        studentsSwiper() {
            this.students = new Swiper(".studentsSwiper", {
                slidesPerView: 1,
                spaceBetween: 24,
                loop: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 67,
                    },
                },
            });
        },
    },
    async mounted() {
        const { data: banners } = await apiGetBanner();
        const { data: news } = await apiGetLatestNews();
        const { data: recentActivities } = await apiGetRecentActivities();
        this.banners = banners;
        this.lastNews = news.data.sort((a, b) => {
            return dayjs(b.createdate).$d.getTime() - dayjs(a.createdate).$d.getTime();
        });
        this.recentActivities = recentActivities.data.sort((a, b) => {
            return dayjs(b.createdate).$d.getTime() - dayjs(a.createdate).$d.getTime();
        });
        this.timer = setInterval(() => {
            this.sec++;
        }, 3500);
        this.$nextTick(() => {
            this.bannerSwiper();
            this.studentsSwiper();
        });
    },
};
</script>
<template>
    <div>
        <section>
            <div class="swiper-container mySwiper bner-infor-bg relative overflow-hidden">
                <div class="swiper-wrapper">
                    <a :href="banner.url" class="swiper-slide bg-center bg-cover" v-for="banner in banners.data" :key="banner.id" :style="{ 'background-image': `url(${banner.img})` }"></a>
                </div>
                <div
                    class="banner-prev bg-[rgba(255,255,255,0.5)] rounded-full h-44px w-44px absolute flex items-center cursor-pointer z-8 transform -translate-y-1/2 top-1/2 justify-center left-8px shadow-lg"
                >
                    <img src="@/assets/images/next-btn.svg" class="transform rotate-180" alt="" />
                </div>
                <div
                    class="banner-next rounded-full bg-[rgba(255,255,255,0.5)] w-44px h-44px absolute flex items-center cursor-pointer z-8 transform -translate-y-1/2 top-1/2 justify-center right-8px shadow-lg"
                >
                    <img src="@/assets/images/next-btn.svg" alt="" />
                </div>
            </div>
        </section>
        <div class="relative w-full sn-500:pt-450px sm:pt-300px pt-0 md:pt-305px mb-20px sm:mb-80px">
            <div
                class="max-w-787px static sn-500:absolute sn-500:rounded-10px rounded-none lg:p-40px p-20px py-30px  shadow-md sn-500:w-9/10 bg-white md:-top-55px top-[-20px] h-auto left-1/2 transform translate-x-0 w-full  sn-500:-translate-x-1/2 z-2 flex sm:flex-row flex-col items-center"
            >
                <div class="w-7/10 sm:w-5/12 sm:mr-7 mr-0 mb-20px sm:mb-0">
                    <div class="pt-[100%] relative">
                        <div class="guide-img text-14px sm:text-16px leading-5 bg-center bg-contain bg-no-repeat absolute w-full h-full top-0 left-0">
                            <div
                                :class="{ 'animate-bounce': sec % 5 === 1 }"
                                class="w-[25%] h-[25%] bg-[#8295C3] rounded-full flex items-center justify-center break-all text-center font-semibold absolute left-[21%] top-[3%] text-white"
                            >
                                TOEFL<br />/SAT
                            </div>
                            <div
                                :class="{ 'animate-bounce': sec % 5 === 2 }"
                                class="w-[22%] h-[22%]  rounded-full bg-[#8ABEDB] flex items-center justify-center break-all text-center font-semibold absolute right-[6%] top-[20%] text-white"
                            >
                                GPA
                            </div>
                            <div
                                :class="{ 'animate-bounce': sec % 5 === 3 }"
                                class="w-[30%] h-[30%] rounded-full bg-[#6E9AC2] flex flex-col items-center justify-center break-all text-center font-semibold absolute left-[0%] bottom-[24%] text-white"
                            >
                                <p>Activity</p>
                                <p class="text-12px hidden sm:block">
                                    (Premium Only)
                                </p>
                            </div>
                            <div
                                :class="{ 'animate-bounce': sec % 5 === 4 }"
                                class="w-[22%] h-[22%] rounded-full bg-[#93B6C6] flex items-center justify-center break-all text-center font-semibold absolute left-[42%] bottom-[2%] text-white"
                            >
                                AP/IB
                            </div>
                            <div
                                :class="{ 'animate-bounce': sec % 5 === 0 }"
                                class="w-[24%] h-[24%] rounded-full bg-[#93C4C7] flex items-center justify-center break-all text-center font-semibold absolute right-[1%] bottom-[20%] text-white"
                            >
                                專業<br />顧問
                            </div>
                            <div
                                class="w-[38%] h-[38%] flex flex-col items-center justify-center rounded-full bg-[#4C73AA] flex items-center justify-center break-all text-center font-semibold absolute left-[56%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white"
                            >
                                <p class="text-18px mb-4px">菁英培訓</p>
                                <p class="lg:text-14px text-12px hidden sm:block">
                                    Elite & Premium
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-main-blue flex-1">
                    <p class="lg:text-2xl text-xl mb-4 font-semibold text-center sm:text-left">
                        菁英培訓 Elite&Premium
                    </p>
                    <p class="mb-4 text-xl lg:text-2xl font-semibold text-center sm:text-left">
                        全台最完善的個人化留學培訓
                    </p>
                    <div class="flex mb-4 sm:justify-start justify-center">
                        <img src="@/assets/images/star.svg" alt="star" class="mr-12px last:mr-0" v-for="star in 5" :key="star" />
                    </div>
                    <p style="color:#003467" class="text-sm">
                        名門菁英培訓經長達十多年規劃的實戰經驗以及調整，現今已成為業界最完善的全方位的個人顧問制度。由經驗豐富的留學顧問及私人顧問，一同帶領學生針對海外大學申請所著重的各項類別，系統式培訓。規劃將結合學校排程、學生的專長及興趣、個人行程安排，將時間做最有效的利用。
                    </p>
                </div>
            </div>
        </div>
        <section class="max-w-1145px mx-auto">
            <div class="flex lg:flex-row flex-col items-center justify-between px-30px mb-48px">
                <div class="text-dark-blue font-normal pl-20px sm:pl-90px mt-20px lg:mt-0">
                    <p class="text-2xl mb-12px text-main-blue font-bold">
                        Why Primerica?
                    </p>
                    <p class="text-sm mb-12px">
                        為甚麼大家願意把孩子交給名門呢？
                    </p>
                    <p class="text-sm mb-12px">名門為您的孩子提供</p>
                    <ul class="text-[#E28501] text-sm list-inside list-disc">
                        <li class="mb-12px font-semibold">多樣課程的安排</li>
                        <li class="mb-12px font-semibold">專屬顧問的規劃</li>
                        <li class="mb-12px font-semibold">留學申請的準備</li>
                        <li class="mb-12px font-semibold">菁英培訓的計畫</li>
                    </ul>
                    <p class="text-sm">
                        讓您的孩子在出國讀書前，就比別人實力更堅強！
                    </p>
                </div>
                <div class="sm:w-560px w-full relative order-first lg:order-last">
                    <div class="pt-[56.24%]">
                        <iframe
                            src="https://www.youtube.com/embed/y-GzW8okKRQ"
                            title="YouTube video player"
                            frameborder="0"
                            class="shadow-lg rounded-8px  absolute top-0 left-0 h-full w-full"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div class="md:flex items-start px-20px md:px-60px mb-50px">
                <img src="@/assets/images/new-course-img.png" alt="course-img" class="w-367px mb-20px md:mb-0 md:mr-20px mx-auto lg:mr-58px rounded-8px shadow-lg" />
                <div class="flex flex-col">
                    <span style="color:#BBA07A" class="text-sm font-bold">New class</span>
                    <p class="mb-12px text-lg text-main-blue font-bold">
                        最新開課資訊，歡迎聯繫詢問細節！
                    </p>
                    <p class="text-dark-blue leading-32px line-clamp-5">
                        2021/04/29 名門有全台最完整系統化課綱及專業師資。為您的孩子，做最細心的學習進度規劃。 常態班：主要正課涵蓋TOEFL基礎/策略/強化
                        三階段課程、SAT策略及模考檢討訓練。輔助課程包含單字文法和數學課...等。 正式考培訓班：SSAT (通常於8月~11月開班)、AP (通常於9月~隔年4月開班)及不定期加開TOEFL考前衝刺班。
                        其他課程：寒暑假專屬English全英夏令營，從小培養孩子英文能力。
                    </p>
                    <router-link
                        :to="{ name: 'Courses', query: { kindid: '1180' } }"
                        class="flex items-center justify-center h-40px self-end w-180px font-semibold transition duration-500 hover:(bg-[#BBA07A] text-white) rounded-8px focus:outline-none mt-24px border-1 border-solid border-[#BBA07A] text-[#BBA07A]"
                    >
                        更多介紹 >>
                    </router-link>
                </div>
            </div>
            <div class="flex lg:justify-end mb-80px h-118px relative mx-auto md:mr-60px">
                <div
                    class="left-1/2 w-full md:left-auto transform md:translate-x-0 -translate-x-1/2 animation-one absolute right-0 opacity-0 bg-white shadow-lg rounded-10px px-36px py-20px flex items-center max-w-350px"
                    style="border:1px solid #C4C4C4"
                >
                    <img src="@/assets/images/user-1.png" alt="user" class="w-76px mr-20px" />
                    <div>
                        <p class="text-18px mb-8px text-main-blue font-bold">
                            Evelyn
                        </p>
                        <p class="text-14px leading-5 text-dark-blue line-clamp-3">
                            謝謝名門一路帶我從TOEFL模考26分， 到正式考94分！
                        </p>
                    </div>
                </div>
                <div
                    class="left-1/2 w-full md:left-auto transform md:translate-x-0 -translate-x-1/2 absolute animation-two right-0 opacity-0 bg-white shadow-lg rounded-10px px-36px py-20px flex items-center max-w-350px"
                    style="border:1px solid #C4C4C4"
                >
                    <img src="@/assets/images/user-2.png" alt="user" class="w-76px mr-20px" />
                    <div>
                        <p class="text-18px mb-8px text-main-blue font-bold">
                            Shannon
                        </p>
                        <p class="text-14px leading-5 text-dark-blue line-clamp-3">
                            Sean老師的SAT課程獲益良多，原本一直 卡在1260的我，最後終於考到1560的高分！-
                        </p>
                    </div>
                </div>
                <div
                    class="left-1/2 w-full md:left-auto transform md:translate-x-0 -translate-x-1/2 absolute animation-three right-0 opacity-0 bg-white shadow-lg rounded-10px px-36px py-20px flex items-center max-w-350px"
                    style="border:1px solid #C4C4C4"
                >
                    <img src="@/assets/images/user-3.png" alt="user" class="w-76px mr-20px" />
                    <div>
                        <p class="text-18px mb-8px text-main-blue font-bold">
                            Brian
                        </p>
                        <p class="text-14px leading-5 text-dark-blue line-clamp-3">
                            跟著Lin老師AP培訓，進入2021台灣經奧 決賽！非常感謝老師。
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="h-110px slogan-bg bg-cover bg-center mb-80px bg-fixed flex items-center flex-col justify-center">
            <img src="@/assets/images/slogan-img-1.png" class="md:w-6/12 sm:w-8/12 w-9/10  max-w-580px" alt="" />
            <img src="@/assets/images/slogan-img-2.png" class="md:w-6/12 sm:w-8/12 w-9/10  max-w-580px" alt="" />
        </section>
        <section class="max-w-1145px mx-20px lg:mx-40px xl:mx-auto">
            <div class="mb-24px border-b-1 border-solid border-[#C4C4C4] relative font-semibold text-22px">
                <ul class="flex">
                    <span
                        class="border-b-2 border-solid border-[#005594] absolute bottom-[-1px] transition-position duration-500 w-1/2 sm:w-200px"
                        :class="[comps === 'NewsArticleComp' ? 'left-0' : 'sm:left-[200px] left-[50%]']"
                    ></span>
                    <li
                        class="cursor-pointer w-1/2 sm:w-200px h-40px flex items-center justify-center "
                        :class="[comps === 'NewsArticleComp' ? 'text-[#005594]' : 'text-[#878585] hover:bg-[#F1F0F0] transition-color duration-300']"
                        @click="comps = 'NewsArticleComp'"
                    >
                        <i class="fas fa-bullhorn mr-8px"></i>
                        最新文章
                    </li>
                    <li
                        class="cursor-pointer flex justify-center items-center w-1/2 sm:w-200px h-40px"
                        :class="[comps === 'Activities' ? 'text-[#005594]' : 'text-[#878585] hover:bg-[#F1F0F0] transition-color duration-300']"
                        @click="comps = 'Activities'"
                    >
                        <i class="fas fa-globe-europe mr-8px"></i>
                        近期活動
                    </li>
                </ul>
            </div>
            <!-- 最新文章 -->
            <component :is="comps" :lastNews="lastNews" :recentActivities="recentActivities" v-if="lastNews.length > 0" />
        </section>
        <section class="h-771px pt-235px student-suggection bg-cover bg-center">
            <div class="max-w-1145px mx-auto flex justify-center relative">
                <div class="studentsSwiper relative swiper-container overflow-hidden relative mx-[-20px] lg:mx-[-60px] px-20px lg:px-60px w-10/12 xl:w-auto">
                    <div class="flex items-center swiper-wrapper">
                        <StudentSuggection v-for="suggection in studentSuggections" :key="suggection.name" :suggection="suggection" class="swiper-slide" />
                    </div>
                    <div class="swiper-button-prev bg-white rounded-full h-44px w-44px absolute left-0 shadow-lg">
                        <img src="@/assets/images/next-btn.svg" class="transform rotate-180" alt="" />
                    </div>
                    <div class="swiper-button-next rounded-full bg-white w-44px h-44px absolute right-0 shadow-lg">
                        <img src="@/assets/images/next-btn.svg" alt="" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style src="@/assets/css/banner.css"></style>
<style lang="postcss" scoped>
.menu-subtitle {
    &:hover {
        > img {
            @apply block;
        }
    }
}

.menu-item {
    > div {
        @apply hidden;
        > div {
            &::before {
                content: "";
                @apply w-15px h-15px bg-[#fafafa] top-10px absolute left-1/2 transform -z-1 -translate-x-1/2 rotate-45;
            }
        }
    }
    &:hover {
        @apply text-[#F2BA1F] border-1 border-solid border-[#F2BA1F];
        @apply duration-300;
        &:after {
            content: "";
            @apply bg-[#F2BA1F];
        }
        &:before {
            content: "";
            @apply bg-[#F2BA1F];
        }
        > div {
            @apply block;
        }
    }
}
.guide-img {
    background-image: url("../assets/images/guide.png");
}
.prev-btn {
    &:after {
        content: "";
        @apply absolute left-0 top-0 w-60px h-full relative z-20 bg-blue-1;
    }
}

.animation-one {
    animation: ani1 16s ease infinite;
    @keyframes ani1;
}
@keyframes ani1 {
    0% {
        @apply opacity-0 bottom-[-40px];
    }
    17% {
        @apply opacity-100 bottom-0;
    }
    33% {
        @apply opacity-0 bottom-40px;
    }
    50% {
    }
    67% {
    }
    83% {
    }
    100% {
    }
}
.animation-two {
    animation: ani2 16s ease infinite;
    @keyframes ani2;
}
@keyframes ani2 {
    0% {
    }
    17% {
    }
    33% {
        @apply opacity-0 bottom-[-40px];
    }
    50% {
        @apply opacity-100 bottom-0;
    }
    67% {
        @apply opacity-0 bottom-40px;
    }
    83% {
    }
    100% {
    }
}
.animation-three {
    animation: ani3 16s ease infinite;
    @keyframes ani3;
}
@keyframes ani3 {
    0% {
    }
    17% {
    }
    33% {
    }
    50% {
    }
    67% {
        @apply opacity-0 bottom-[-40px];
    }
    83% {
        @apply opacity-100 bottom-0;
    }
    100% {
        @apply opacity-0 bottom-40px;
    }
}

.swiper-button-next,
.swiper-button-prev {
    &:after {
        content: "";
    }
}
.student-suggection {
    background-image: url("../assets/images/suggection-bg.png");
}
.slogan-bg {
    background-image: url("../assets/images/slogan.png");
}
.text-main-blue {
    color: #005594;
}
.text-dark-blue {
    color: #003467;
}
</style>
