<script>
import { recentActivities } from "@/assets/data/studentSuggection";
import Swiper from "swiper";
export default {
    props: {
        recentActivities: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            activities: recentActivities,
        };
    },
    methods: {
        newsTabsSwiper() {
            this.galleryThumbs = new Swiper("#activitiesThumbs", {
                slidesPerView: 2,
                spaceBetween: 20,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 38,
                    },
                },
            });
            this.swiper = new Swiper("#activities", {
                slidesPerView: 1,
                thumbs: {
                    swiper: this.galleryThumbs,
                },
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.newsTabsSwiper();
        });
    },
};
</script>
<template>
    <div>
        <div class="swiper-container overflow-hidden mb-26px" id="activities">
            <div class="swiper-wrapper">
                <div class="block md:flex swiper-slide" v-for="(activity, idx) in recentActivities" :key="idx">
                    <div
                        :style="{
                            'background-image': `url(${activities[idx % 5].image})`,
                        }"
                        class="mr-20px lg:mr-50px h-300px md:h-400px md:max-w-550px mb-20px md:mb-0 max-w-1000px w-full md:w-1/2 lg:w-7/12 bg-center bg-no-repeat bg-cover"
                    ></div>

                    <div class="flex flex-col items-stretch flex-1 max-w-1/2 relative -md:max-w-full">
                        <p class="text-[#BBA07a] text-16px lg:text-24px font-bold mb-8px lg:mb-20px">LATEST NEWS</p>
                        <p class="text-20px lg:text-24px mb-8px lg:mb-20px text-[#005594] font-bold">{{ activity.title }}</p>
                        <p class="text-[#003467] leading-8">{{ activity.createdate }}</p>
                        <div class="activity-body" v-html="activity.body"></div>
                        <a
                            :href="`http://www.primerica.com.tw/eventsdetail-${activity.id}.html`"
                            target="_blank"
                            class="flex items-center justify-center h-40px w-180px font-semibold self-end transition duration-500 hover:(bg-[#BBA07A] text-white) rounded-8px focus:outline-none mt-24px float-right border-1 border-solid border-[#BBA07A] text-[#BBA07A]"
                        >
                            更多介紹 >>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-container overflow-hidden gallery-thumbs" id="activitiesThumbs">
            <div class="swiper-wrapper">
                <div
                    v-for="(activity, idx) in recentActivities"
                    :key="idx"
                    class="w-1/4  swiper-slide cursor-pointer bg-center bg-cover tab-height"
                    :style="{
                        'background-image': `url(${activities[idx % 5].image})`,
                    }"
                >
                    <div class="flex flex-col items-center justify-center h-full  transition-colors duration-500 p-4 font-bold" :class="[`overlay-${idx + 1}`]">
                        <p class="text-[#FBFF44] text-14px lg:text-24px mb-12px">{{ activity.title }}</p>
                        <p class="text-white text-14px lg:text-16px">{{ activity.createdate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="postcss" scoped>
.activity-body {
    @apply text-[#003467] leading-7 mb-40px h-200px overflow-hidden relative;
    &::before {
        content: "";
        @apply absolute bottom-0 right-0 w-full h-50px bg-gradient-to-t from-white;
    }
}
/deep/.activity-body {
    h1 h2,
    h3,
    h4,
    h5,
    h6,
    span {
        font-size: 16px !important;
    }
}
@media (max-width: 768px) {
    .tab-height {
        height: 120px;
    }
}
.tab-height {
    height: 186px;
}
.activity-tab-1 {
    background-image: url("../assets/images/activity-tab-1.png");
}
.overlay-1 {
    background-color: rgba(4, 50, 96, 0.5);
    &:hover {
        background-color: rgba(4, 50, 96, 0.9);
    }
}
.activity-tab-2 {
    background-image: url("../assets/images/activity-tab-2.png");
}
.overlay-2 {
    background-color: rgba(10, 57, 43, 0.5);
    &:hover {
        background-color: rgba(10, 57, 43, 0.9);
    }
}
.activity-tab-3 {
    background-image: url("../assets/images/activity-tab-3.png");
}
.overlay-3 {
    background-color: rgba(89, 59, 42, 0.6);
    &:hover {
        background-color: rgba(89, 59, 42, 0.9);
    }
}
</style>
