<script>
import { newsArticle } from "@/assets/data/studentSuggection";
import Swiper from "swiper";
export default {
    props: {
        lastNews: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            newsArticle,
        };
    },
    methods: {
        newsTabsSwiper() {
            this.galleryThumbs = new Swiper("#newsTabs", {
                slidesPerView: 2,
                spaceBetween: 20,
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 38,
                    },
                },
            });
            this.swiper = new Swiper("#news", {
                slidesPerView: 1,
                spaceBetween: 20,
                thumbs: {
                    swiper: this.galleryThumbs,
                },
            });
        },
    },

    mounted() {
        this.lastNews.forEach((news) => {
            news.image = "https://www.primerica.com.tw/images/epost/d79b9d2b169aae1a9e3198feaf7d050f.jpg";
        });
        setTimeout(() => {
            this.newsTabsSwiper();
        }, 0);
    },
};
</script>
<template>
    <div>
        <div class="swiper-container mb-26px overflow-hidden" id="news">
            <div class="swiper-wrapper">
                <div class="block md:flex items-stretch swiper-slide" v-for="(news, idx) in lastNews" :key="news.id">
                    <div
                        :style="{
                            'background-image': `url(${newsArticle[idx % 5].image})`,
                        }"
                        class="mr-20px lg:mr-50px h-300px md:h-400px md:max-w-550px mb-20px md:mb-0 max-w-1000px w-full md:w-1/2 lg:w-7/12 bg-center bg-no-repeat bg-cover"
                    ></div>
                    <div class="flex flex-col items-stretch flex-1 max-w-1/2 relative -md:max-w-full">
                        <p class="text-[#BBA07a] text-16px lg:text-24px font-bold mb-8px lg:mb-20px">
                            LATEST NEWS
                        </p>
                        <p class="text-20px lg:text-24px mb-8px lg:mb-20px text-[#005594] font-bold">
                            {{ news.title }}
                        </p>
                        <p class="text-[#003467] leading-8">
                            {{ news.createdate }}
                        </p>
                        <div class="news-body" v-html="news.body"></div>
                        <div class="self-end flex">
                            <a
                                :href="`https://www.primerica.com.tw/newsdetail-${news.id}.html`"
                                target="_blank"
                                class="h-40px w-120px font-semibold flex items-center justify-center mr-20px transition duration-500 hover:(bg-[#BBA07A] text-white) rounded-8px focus:outline-none float-right border-1 border-solid border-[#BBA07A] text-[#BBA07A]"
                            >
                                詳細說明 >>
                            </a>
                            <a
                                href="https://www.primerica.com.tw/news-1.html"
                                target="_blank"
                                class="h-40px w-120px font-semibold transition flex items-center justify-center duration-500 hover:(bg-[#BBA07A] text-white) rounded-8px focus:outline-none float-right border-1 border-solid border-[#BBA07A] text-[#BBA07A]"
                            >
                                更多介紹 >>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-container overflow-hidden gallery-thumbs" id="newsTabs">
            <div class="swiper-wrapper">
                <div
                    class="w-1/4  swiper-slide cursor-pointer bg-center bg-cover tab-height"
                    v-for="(news, idx) in lastNews"
                    :key="news.id"
                    :style="{
                        'background-image': `url(${newsArticle[idx % 5].image})`,
                    }"
                >
                    <div class="flex flex-col items-center justify-center h-full  transition-colors duration-500" :class="`overlay-${(idx % 5) + 1}`">
                        <p class="text-[#FBFF44] text-16px lg:text-18px font-semibold mb-12px max-w-8/10">
                            {{ news.title }}
                        </p>
                        <p class="text-white font-semibold text-14px sm:text-16px">
                            {{ news.createdate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="postcss" scoped>
.news-body {
    @apply text-[#003467] leading-7 mb-40px h-200px overflow-hidden relative;
    &::before {
        content: "";
        @apply absolute bottom-0 right-0 w-full h-50px bg-gradient-to-t from-white;
    }
}
/deep/.news-body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
        font-size: 16px !important;
    }
}
@media (max-width: 768px) {
    .tab-height {
        height: 120px;
    }
}
.tab-height {
    height: 186px;
}

.overlay-1 {
    background-color: rgba(91, 45, 16, 0.6);
    &:hover {
        background-color: rgba(91, 45, 16, 0.9);
    }
}

.overlay-2 {
    background-color: rgba(55, 103, 138, 0.6);
    &:hover {
        background-color: rgba(55, 103, 138, 0.9);
    }
}

.overlay-3 {
    background-color: rgba(91, 45, 16, 0.6);
    &:hover {
        background-color: rgba(91, 45, 16, 0.9);
    }
}

.overlay-4 {
    background-color: rgba(18, 127, 152, 0.7);
    &:hover {
        background-color: rgba(18, 127, 152, 0.9);
    }
}

.overlay-5 {
    background-color: rgba(92, 103, 158, 0.7);
    &:hover {
        background-color: rgba(92, 103, 158, 0.9);
    }
}
</style>
