<script>
import MenuItemMobile from "@/components/MenuItemMobile";
import { menuList, goodLinks, iconGroup } from "@/assets/data/studentSuggection";

export default {
  components: { MenuItemMobile },
  data() {
    return {
      menuList, // menu 清單
      subtitleIdx: -1,
      openMobileMenu: false,
      goodLinks,
      iconGroup,
    };
  },
  methods: {
    toContactUs() {
      const contactUsDom = document.getElementById("contact-us").getBoundingClientRect();
      const getOffset = contactUsDom.top + window.pageYOffset - document.documentElement.clientTop;
      window.scrollTo({ top: getOffset, behavior: "smooth" });
    },
    mobileToContact() {
      this.openMobileMenu = false;
      this.toContactUs();
    },
  },
};
</script>
<template>
  <div id="app" style="background-color:#fafafa">
    <div
      class="fixed top-0 left-0 w-full h-full flex items-stretch justify-start z-15"
      :class="[openMobileMenu ? 'bg-[rgba(0,0,0,0.7)] pointer-events-auto' : 'bg-transparent pointer-events-none']"
    >
      <div
        class="relative max-w-375px bg-[#212328] w-full h-100vh pt-50px transform transition duration-500"
        :class="[!openMobileMenu ? 'translate-x-[-375px]' : 'translate-x-0']"
      >
        <i class="fas fa-times absolute left-12px top-12px text-30px text-white cursor-pointer" @click="openMobileMenu = false"></i>
        <ul class="">
          <MenuItemMobile v-for="(item, idx) in menuList" :key="idx" :menuItem="item" :openSubtitle="subtitleIdx === idx" @active-item="subtitleIdx = idx" />
          <li class="text-white cursor-pointer py-12px px-12px" @click="mobileToContact">
            聯絡我們
          </li>
          <li class="text-white cursor-pointer py-12px px-12px"><a href="http://www.primerica.com.tw/member_login.php">學員登入</a></li>
        </ul>
      </div>
    </div>
    <div class="bg-[#212328] flex justify-center items-center">
      <div class="max-w-1145px flex items-center justify-center h-70px lg:h-112px w-full mx-20px xl:mx-0">
        <div class="transition-all duration-500 flex justify-between w-full items-center py-4px relative">
          <i class="fas fa-bars text-24px block lg:hidden text-[#F2BA1F] cursor-pointer" @click="openMobileMenu = !openMobileMenu"></i>
          <a href="/">
            <img
              src="@/assets/images/logo.png"
              alt="logo"
              class="w-200px mr-20px lg:static absolute left-1/2 top-1/2 transform lg:translate-x-0 lg:translate-y-0 -translate-x-1/2 -translate-y-1/2 "
            />
          </a>
          <ul class="flex-wrap lg:flex hidden">
            <li v-for="(item, idx) in menuList" :key="idx" class="flex items-center justify-center">
              <a
                :href="item.url.length === 0 ? false : item.url"
                class="text-white py-4px relative px-12px border-1 border-solid border-[#212328] rounded-8px cursor-pointer menu-item "
              >
                {{ item.title }}
                <div v-if="item.list.length !== 0" class="absolute pt-15px bottom-0 text-[#333232] left-0 transform translate-y-[100%] z-8">
                  <div class="bg-[#fafafa] p-10px divide-y-1 text-14px rounded-8px">
                    <p v-for="(subtitle, idx) in item.list" :key="idx" class="py-8px px-8px relative menu-subtitle">
                      <img class="absolute left-[-4px] top-1/2 transform -translate-y-1/2 hidden" src="@/assets/images/focus.svg" />
                      <a class="whitespace-nowrap" :href="subtitle.url">{{ subtitle.subtitle }}</a>
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li class="menu-item text-white mr-10px py-4px relative px-12px border-1 border-solid border-[#212328] rounded-8px cursor-pointer" @click="toContactUs">
              聯絡我們
            </li>
            <li class="menu-item text-white mr-10px py-4px relative px-12px border-1 border-solid border-[#212328] rounded-8px cursor-pointer">
              <a href="http://www.primerica.com.tw/member_login.php">學員登入</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <router-view></router-view>

    <footer class="bg-[#111111]" id="contact-us">
      <div class="max-w-1145px mx-auto">
        <div class="pb-40px md:pb-78px text-white font-semibold block xl:flex items-center pt-0 xl:pt-78px">
          <iframe
            class="pr-0 xl:pr-100px h-250px w-full xl:w-417px xl:mb-0 mb-20px"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.7740013741213!2d121.54782171549385!3d25.041742583968784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abc515212187%3A0x4b16c48fad319b4a!2zMTA25Y-w5YyX5biC5aSn5a6J5Y2A5b-g5a2d5p2x6Lev5Zub5q61MTU16Jmf!5e0!3m2!1szh-TW!2stw!4v1495112392750"
            frameborder="0"
          ></iframe>
          <div class="xl:flex block items-center mx-20px xl:mx-0 flex-1 pl-0 xl:pl-60px border-0 xl:border-l-1 border-solid border-[#C4C4C4]">
            <div class="mb-0 md:mb-40px">
              <div class="md:flex items-center mb-24px block">
                <img src="@/assets/images/logo.png" class="pl-20px mb-12px md:mb-0 md:pl-0 w-220px" alt="" />
                <p class="mr-20px xl:mr-0 pl-20px md:pl-40px md:pt-15px pt-4px text-14px md:leading-6 leading-5 md:text-16px">
                  名門留遊學有限公司 / 台北市私立名學文理短期補習班 (台北市補習班證字第6140號)
                </p>
              </div>
              <div class="md:flex items-start block">
                <div class="mr-20px pl-24px mb-40px md:mb-0">
                  <p class="text-xl text-white mb-12px"><span class="text-[#F7BB1B] mr-8px">聯絡我們</span>Contacts us</p>
                  <div class="max-w-320px">
                    <div class="flex items-center mb-12px">
                      <i class="fas fa-phone-alt"></i>
                      <a href="tel: +(02)2751-3624" class="ml-12px cursor-pointer">(02)2751-3624</a>
                    </div>
                    <div class="flex items-center mb-12px">
                      <i class="fas fa-print"></i>
                      <p class="ml-12px cursor-pointer">(02)2751-3613</p>
                    </div>
                    <div class="flex items-center mb-12px">
                      <i class="fas fa-envelope"></i>
                      <a href="mailto:gary.chang@primerica.com.tw" class="ml-12px">gary.chang@primerica.com.tw</a>
                    </div>
                    <div class="flex items-start">
                      <i class="fas fa-map-marker-alt"></i>
                      <p class="ml-12px">106 台北市忠孝東路四段155號4樓之2 (忠孝敦化站7號出口，九如大厦)</p>
                    </div>
                  </div>
                </div>
                <div class="md:pl-0 pl-20px mb-40px md:mb-0">
                  <p class="text-xl mb-12px"><span class="mr-8px text-[#F7BB1B]">好站連結</span>Good Links</p>
                  <ul class="list-disc list-inside">
                    <li v-for="(link, idx) in goodLinks" :key="idx" class="mb-12px">
                      <a :href="link.url">{{ link.title }}</a>
                    </li>
                  </ul>
                </div>
                <div class="pl-20px md:pl-0 ml-0 md:ml-40px flex justify-start md:block">
                  <a
                    v-for="(icon, idx) in iconGroup"
                    :key="idx"
                    :href="icon.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    :style="{ 'background-image': `url(${icon.image})` }"
                    class="w-30px block h-30px md:w-50px md:h-50px mr-12px md:mr-20px md:mr-0 mb-0 md:mb-20px last:mb-0 bg-cover bg-center cursor-pointer"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="bg-[#F6BA1E]">
      <p class="max-w-1145px mx-auto text-right py-8px font-bold pr-20px xl:pr-0">版權所有© 2021名門留遊學教育中心</p>
    </div>
  </div>
</template>

<style lang="postcss">
.menu-item {
  > div {
    @apply hidden;
    > div {
      &::before {
        content: "";
        @apply w-15px h-15px bg-[#fafafa] top-10px absolute left-1/2 transform -z-1 -translate-x-1/2 rotate-45;
      }
    }
  }
  &:hover {
    @apply text-[#F2BA1F] border-1 border-solid border-[#F2BA1F];
    @apply duration-300;
    &:after {
      content: "";
      @apply bg-[#F2BA1F];
    }
    &:before {
      content: "";
      @apply bg-[#F2BA1F];
    }
    > div {
      @apply block;
    }
  }
}
.prev-btn {
  &:after {
    content: "";
    @apply absolute left-0 top-0 w-60px h-full relative z-20 bg-blue-1;
  }
}
.animation-one {
  animation: ani1 16s ease infinite;
  @keyframes ani1;
}
@keyframes ani1 {
  0% {
    @apply opacity-0 bottom-[-40px];
  }
  17% {
    @apply opacity-100 bottom-0;
  }
  33% {
    @apply opacity-0 bottom-40px;
  }
  50% {
  }
  67% {
  }
  83% {
  }
  100% {
  }
}
.animation-two {
  animation: ani2 16s ease infinite;
  @keyframes ani2;
}
@keyframes ani2 {
  0% {
  }
  17% {
  }
  33% {
    @apply opacity-0 bottom-[-40px];
  }
  50% {
    @apply opacity-100 bottom-0;
  }
  67% {
    @apply opacity-0 bottom-40px;
  }
  83% {
  }
  100% {
  }
}
.animation-three {
  animation: ani3 16s ease infinite;
  @keyframes ani3;
}
@keyframes ani3 {
  0% {
  }
  17% {
  }
  33% {
  }
  50% {
  }
  67% {
    @apply opacity-0 bottom-[-40px];
  }
  83% {
    @apply opacity-100 bottom-0;
  }
  100% {
    @apply opacity-0 bottom-40px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  &:after {
    content: "";
  }
}

html,
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}
</style>
