<script>
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
    openSubtitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    activeItem() {
      this.$emit("active-item");
    },
  },
};
</script>
<template>
  <li>
    <!-- <pre>{{ menuItem.url }}</pre> -->
    <a
      :href="menuItem.url.length === 0 ? false : menuItem.url"
      class="text-white px-12px cursor-pointer block"
      :class="[{ 'bg-[#F2BA1F] text-[#333232]': openSubtitle }, menuItem.list.length === 0 ? 'py-12px' : 'pt-12px', openSubtitle ? 'divide-y-1' : '']"
      @click="activeItem"
    >
      {{ menuItem.title }}
      <a
        v-for="(subtitle, idx) in menuItem.list"
        :href="subtitle.url"
        class="bg-[#fafafa] mx-[-12px] px-20px block first:mt-10px text-[#333232]"
        :class="[{ 'h-0 overflow-hidden ': !openSubtitle }, !openSubtitle ? 'py-0' : 'py-8px']"
        :key="idx"
      >
        {{ subtitle.subtitle }}
      </a>
    </a>
  </li>
</template>
