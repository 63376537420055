import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "windi.css";

import "./assets/css/global.css";

Vue.config.productionTip = false;

import VueFbCustomerChat from "vue-fb-customer-chat";
import router from "./router";

Vue.use(VueFbCustomerChat, {
  page_id: 79380219340, //  change 'null' to your Facebook Page ID,
  theme_color: "#3088FF", // theme color in HEX
  locale: "en_US", // default 'en_US'
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
