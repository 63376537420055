export const menuList = [
  {
    title: "關於名門留學",
    url: "",
    list: [
      { subtitle: "名門服務", url: "http://www.primerica.com.tw/about.php" },
      { subtitle: "名門環境", url: "http://www.primerica.com.tw/environment.php" },
      { subtitle: "名門顧問群", url: "http://www.primerica.com.tw/consultants.php" },
    ],
  },
  { title: "菁英培訓", url: "https://www.primerica.com.tw/newsdetail-1921.html", list: [] }, 
  { title: "最新課程", url: "http://www.primerica.com.tw/#/courses?kindid=1180", list: [] },
  {
    title: "留學申请",
    url: "",
    list: [
      { subtitle: "寄宿中學", url: "http://www.primerica.com.tw/apply/1/index.php" },
      { subtitle: "美國大學", url: "http://www.primerica.com.tw/apply/2/index.php" },
    ],
  },
  {
    title: "留學考試與課程",
    url: "",
    list: [
      { subtitle: "English", url: "http://www.primerica.com.tw/courses-1182.html" },
      { subtitle: "TOEFL", url: "http://www.primerica.com.tw/courses-1180.html" },
      { subtitle: "SAT/ACT", url: "http://www.primerica.com.tw/courses-1181.html" },
      { subtitle: "SSAT/ISEE", url: "http://www.primerica.com.tw/courses-1183.html" },
      { subtitle: "AP", url: "http://www.primerica.com.tw/courses-1011.html" },
      { subtitle: "國際學校課輔", url: "http://www.primerica.com.tw/courses-1185.html" },
      { subtitle: "台灣經濟學奧林匹亞", url: "http://www.primerica.com.tw/courses-1192.html" },
    ],
  },
  {
    title: "暑期海外課程、夏令營",
    url: "",
    list: [
      { subtitle: "海外夏令營", url: "http://www.primerica.com.tw/summer-camp/index.html" },
      { subtitle: "美國知名大學暑期課程", url: "" },
    ],
  },
];
export const studentSuggections = [
  {
    school: "海外中學",
    suggection:
      "Gary, and Joanne are amazing advisors who are always approachable and attentive. They have supported me as I tackled through the various aspects of my application process. I would not have the pleasure of attending my dream school if it weren't for them!",
    image: require("@/assets/images/suggection-1.png"),
    name: "E.H",
  },
  {
    school: "復興",
    suggection:
      "Sean 老師:You are a nice and funny teacher. Thank you for teaching me a lot of English skills outside of the textbook and greatly improved my English abilities. And thank you for helping me to achieve a high score on the SAT!",
    image: require("@/assets/images/suggection-2.png"),
    name: "Sharon",
  },
  {
    school: "政大附中",
    suggection:
      "謝謝Kabby顧問給我很多建議也一直很關心我～托福：謝謝Eliza老師的課幫助我很多 嚴苛的地方也促使我進步！ 謝謝Victor 告訴我很多人生經驗 讓我對未來更有方向 也幫我安排更適合我的說寫課程～Sat：謝謝May老師鼓勵我 也常常關心我的學習近況謝謝Ricky很樂意回答我的數學問題 也鼓勵我多做題目 讓我最後subject數學考800",
    image: require("@/assets/images/suggection-3.png"),
    name: "Evelyn",
  },
  {
    school: "NMH",
    suggection:
      "我很感謝May老師不斷的上課點名要學生回答問題 除了讓我們上課認真度提升之外 我們也同時跟著老師思考。我知道有很多老師是喜歡上課像演講一樣，但是反觀May老師，不斷的要每一位同學參與課程，無形之中也刺激我們思考。總結來說他就是個peerless, assiduous ,and amazing的老師！",
    image: require("@/assets/images/suggection-4.png"),
    name: "Teresa",
  },
  {
    school: "康橋國際",
    suggection: "我跟著Lin老師進行AP經濟學奧林匹亞的訓練，一路訓練下來，經濟學知識確實比較扎實，這一次會進2021的經奧決賽除了驚喜，也謝謝老師一路的培訓。",
    image: require("@/assets/images/suggection-5.png"),
    name: "Brian",
  },
  {
    school: "海外高中",
    suggection:
      "The help teacher Victor gave us really helped me achieve my goal. I initially thought that one week might not be enough for my preparation. However, practicing in the morning and going to class in the evening for a week was indeed helpful. I was able to find my weaknesses quickly and overcome them Victor’s suggestions. Teacher Victor helped me solve my problems in a short time and I really appreciate that!",
    image: require("@/assets/images/suggection-6.png"),
    name: "Ashley ",
  },
  {
    school: "Kaohsiung Morrison Academy",
    suggection: "我很謝謝你們的團隊讓我順利進了我心目中理想的大學。Primerica 團隊的合作以及溝通都非常的融洽 很喜歡也很感謝你們 ❤",
    image: require("@/assets/images/suggection-7.png"),
    name: "Rosalynn",
  },
];
export const newsArticle = [
  {
    image: require("@/assets/images/new-img-1.png"),
  },
  {
    image: require("@/assets/images/new-img-2.png"),
  },
  {
    image: require("@/assets/images/new-img-3.png"),
  },
  {
    image: require("@/assets/images/new-img-4.png"),
  },
  {
    image: require("@/assets/images/new-img-5.png"),
  },
  {
    image: require("@/assets/images/new-img-6.png"),
  },
];
export const recentActivities = [
  {
    image: require("@/assets/images/recentActivity-1.png"),    
  },
  {
    image: require("@/assets/images/recentActivity-2.png"),    
  },
  {
    image: require("@/assets/images/recentActivity-3.png"),    
  },
  {
    image: require("@/assets/images/recentActivity-4.png"),    
  },
  {
    image: require("@/assets/images/recentActivity-5.png"),    
  },
  {
    image: require("@/assets/images/recentActivity-6.png"),    
  },
];
export const goodLinks = [
  { title: "CollegeBoard", url: "https://www.collegeboard.org" },
  { title: "SSAT Official Site", url: "https://www.ssat.org" },
  { title: "ETS ETS-iBT TOEFL", url: "https://www.ets.org/toefl" },
  { title: "名門高中申請諮詢表單", url: "https://forms.gle/YV3Ub88pNv96fU7r6" },
  { title: "名門大學申請諮詢表單", url: "https://forms.gle/ckJaZb6iX1V9G5G19" },
];
export const iconGroup = [
  { image: require("@/assets/images/ig-icon.svg"), url: " https://www.instagram.com/primerica.tw/" },
  { image: require("@/assets/images/line-icon.svg"), url: "https://line.me/R/ti/p/%40ahm3694b" },
  { image: require("@/assets/images/fb-icon.svg"), url: "https://www.facebook.com/garyprimerica/?tsid=0.5517906249579573&source=result" },
];
