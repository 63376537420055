import axios from "@/plugins/axios";

export const apiGetBanner = () => {
    return axios.get("/api/board.php?kind=banner");
};
export const apiGetLatestNews = () => {
    return axios.get("/api/board.php?kind=news&page=1&Sort_Name=id&Sort_Type=desc&pagesize=10");
};
export const apiGetRecentActivities= () => {
    return axios.get("/api/board.php?kind=events");
};
